import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVehicles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vehicles', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchVehicle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/vehicles/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateVehicle(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vehicles/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addVehicle(ctx, vehicleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/vehicles', vehicleData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteVehicle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vehicles/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
